import React from 'react'

function About() {
  return (<></>
    // <main className="h-screen w-screen px-6 bg-slate-100">
    //   <div className="w-full h-full mx:auto flex flex-row items-center">
    //     <span className="text-3xl font-semibold absolute top-40">
    //       Namaste !
    //       <br />
    //       <span className="text-xl font-normal">— Welcome to my online Portfolio . . .</span>
    //       <br />
    //       <span className="text-sm font-normal">--- currently in development</span>
    //     </span>
    //     <div>
    //     </div>
    //   </div>
    // </main>
  )
}

export default About